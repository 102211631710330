@import 'variables';
@import 'table';
@import 'login';
@import 'navbar';
@import 'currencies';
@import 'stores';
body {
  background-color: $background-body;
}
.period-toggle {
  /* background-color: #193852; */
  /* color: #475865; */
  color: #b4b4b4;
  /* border: 1px solid #e8e9ea; */
  max-width: 150px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  /* box-shadow: -2px 2px 5px #ddd; */
  text-shadow: -1px 2px #ddd;
  font-size: 20px;
  span {
    padding: 5px;
    cursor: pointer;
    font-weight: 600;
  }
}
.period-toggle-active {
  color: #193852;
}
.date-input-container {
  display: flex;
  color: #0f293e;
  justify-content: center;
  align-items: center;
  gap: 10px;
  input,
  input:focus,
  input:not([value='']) {
    max-width: 50px;
    padding: 10px 15px;
    background-color: #f6f6f6;
    border: 1px solid #e8e9ea;
    color: #0f293e;
    outline: none;
    box-shadow: none;
    text-align: center;
    margin: 3px;
    font-size: 16px;
    box-shadow: -2px 2px 5px #ddd;
  }
  input:focus {
    border-color: #86b7fe;
  }
}

select {
  margin: 50px;
  outline: none !important;
  max-width: 250px;
  min-height: 30px;
  background-color: #f6f6f6;
  border: 1px solid #e8e9ea;
  cursor: pointer;
  padding: 7px 14px;
  font-size: 16px;
  box-shadow: -2px 2px 5px #ddd;
  option {
    cursor: pointer;
  }
}

.page-level-input-container {
  display: flex;
  justify-content: center;
  /* padding: 0 20%; */
  align-items: bottom;
  gap: 25px;
}

.err-div {
  text-align: center;
  color: red;
  font-size: 25px;
  font-weight: 600;
  width: 100%;
}

.select-super-container {
  display: flex;

  .select-container select {
    margin: 5px;
  }
}

.select-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.both-selects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.select-and-label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}
.select-label {
  width: 50px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  text-shadow: -2px 2px #ddd;
}

.load-and-4days-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  button {
    width: 100px;
    height: 50px;
    padding: 15px 20px;
    font-size: 16px;
    margin: 5px;
    text-transform: uppercase;
  }
}

.table-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;

  table,
  tr,
  th,
  td {
    border: 2px solid $table-border-color;
    border-collapse: collapse;
  }

  th {
    background-color: $table-bg-header;
    color: $table-font-blue;
  }

  td {
    padding: 5px 10px;
    background-color: $table-bg-ad;
    color: $table-font-grey;
  }
  .first-td {
    background-color: $table-bg-adset;
    color: $table-font-blue;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.table-super-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
}

.totals-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  margin-bottom: 5rem;
}

.totals-chart-super-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
}

input,
label {
  cursor: pointer;
}

.summary-table th {
  padding: 5px 10px;
}
.summary-table {
  margin-bottom: 5rem;
}
